<template>
  <Layout :tituloPagina="`Solicitudes de instalación de servicios | ${modo == 'nueva' ? 'Nueva' : 'Edición de'} instalación`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title" v-if="modo == 'nueva'">
          <span class="d-none d-sm-block">
            Nueva solicitud de instalación de servicio
          </span>
          <span class="d-block d-sm-none">
            Nueva solicitud
          </span>
        </h4>
        <h4 class="card-title" v-if="modo == 'edicion'">
          <span class="d-none d-sm-block">
            Edición de solicitud de instalación de servicio
          </span>
          <span class="d-block d-sm-none">
            Edición de solicitud
          </span>
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nueva' ? guardar() : actualizar()"
                  v-if="!bandera_spinner && esEditable()"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner && esEditable()">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" v-if="modo == 'edicion'">
            <label>Estado de la solicitud</label>
            <select
              name="id_estado"
              class="form-select"
              :class="{
                'text-warning': solicitud.id_estado == 1,
                'text-info': solicitud.id_estado == 2,
                'text-primary': solicitud.id_estado == 3
              }"
              style="font-weight: bold"
              v-model="solicitud.id_estado"
              :disabled="!esEditable()"
              v-if="solicitud.id_estado != 4"
            >
              <option :value="1" selected>
                PENDIENTE
              </option>
              <option :value="2">EN PROCESO</option>
              <option :value="3">COMPLETADA</option>
            </select>

            <div
              class="bg-danger text-white pb-2 pt-2 text-center rounded"
              v-if="solicitud.id_estado == 4"
            >
              CANCELADA
            </div>
          </div>
         
          <div class="col-md-3">
            <label>Fecha de instalación</label>
            <input
              ref="fechaProgramada"
              type="datetime-local"
              class="form-control"
              placeholder="YYYY-MM-DD HH:MM:SS"
              v-model="solicitud.fecha_programada"
              :disabled="!esEditable()"
            />
          </div>
          <div class="col-md-5">
            <label>Tiempo estimado</label>
            <div class="row text-center">
              <div class="col-sm-4 col-4">
                <input
                  name="tiempoDias" type="number"
                  class="form-control text-center" ref="tiempoDias"
                  v-model="solicitud.tiempo_estimado_dias"
                  min="0" max="365"
                  placeholder="0"
                  :disabled="!esEditable()"
                />
                <label>Dias</label>
              </div>
              <div class="col-sm-4 col-4">
                <input
                  name="tiempoHoras" type="number"
                  class="form-control text-center" ref="tiempoHoras"
                  v-model="solicitud.tiempo_estimado_horas"
                  min="0" max="23"
                  placeholder="0"
                  :disabled="!esEditable()"
                />
                <label>Horas</label>
              </div>
              <div class="col-sm-4 col-4">
                <input
                  name="tiempoMinutos" type="number"
                  class="form-control text-center" ref="tiempoMinutos"
                  v-model="solicitud.tiempo_estimado_minutos"
                  min="0" max="59"
                  placeholder="0"
                  :disabled="!esEditable()"
                />
                <label>Minutos</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label>Servicio a contratar</label>
            <select
              class="form-select"
              name="id_servicio"
              ref="id_servicio"
              v-model="solicitud.id_servicio"
              :disabled="!esEditable()"
            >
              <option :value="null">Selecionar servicio</option>
              <option v-for="plan in planes" :key="plan.id" :value="plan.id">
                {{ plan.nombre }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Meses incluidos</label>
            <div class="input-group" style="z-index: 1">
              <input
                ref="meses"
                type="number"
                v-model="solicitud.meses_incluidos"
                min="0" max="60"
                class="form-control text-right"
                placeholder="0"
                :disabled="!esEditable()"
              />
              <span class="input-group-text">
                mes{{
                  solicitud.meses_incluidos > 1 ||
                  solicitud.meses_incluidos == 0
                    ? 'es'
                    : ''
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label>Precio de instalación</label>
            <div class="input-group" style="z-index: 1">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <input
                ref="importe"
                type="number"
                step="0.01"
                min="0"
                v-model="solicitud.importe"
                oninput="this.value = Math.abs(this.value)"
                class="form-control text-right"
                placeholder="0.00"
                :disabled="!esEditable()"
              />
              <span class="input-group-text">
                {{ monedaSistema.codigo }}
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <label>Anticipo</label>
            <div class="input-group">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <input
                ref="anticipo"
                type="number"
                step="0.01"
                min="0"
                v-model="solicitud.anticipo"
                oninput="this.value = Math.abs(this.value)"
                class="form-control text-right"
                placeholder="0.00"
                :disabled="!esEditable()"
              />
              <span class="input-group-text">
                {{ monedaSistema.codigo }}
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <label>Método de pago</label>
            <div class="input-group">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <select
                v-model="solicitud.id_metodo_pago"
                :disabled="!esEditable()"
                class="form-select"
              >
                <option v-for="metodoPago in metodosPago" 
                  :value="metodoPago.id"
                  :key="metodoPago.id">
                  {{metodoPago.nombre}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row" v-if="solicitud.id_estado == 4">
          <div class="col-md-12">
            <label class="text-danger">Motivo de cancelación</label>
            <textarea
              placeholder="Motivo de la cancelación"
              cols="30"
              rows="5"
              class="form-control"
              v-model="solicitud.motivo_cancelacion"
              disabled
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- Datos generales del cliente -->
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Datos generales del cliente
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nueva' ? guardar() : actualizar()"
                  v-if="!bandera_spinner && esEditable()"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner && esEditable()">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="modo == 'nueva'">
          <div class="row">
            <label class="col-md-3">
              ¿Es un cliente nuevo?
            </label>
            <div class="col-md-2">
              <select class="form-select" v-model="clienteNuevo">
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <br />
        </div>

        <!-- Nuevo cliente -->
        <div v-if="clienteNuevo == 1">
          <div class="row">
            <div class="col-md-6" v-show="tieneAlias('Nombre')">
              <label>{{cargarAliasCampo('Nombre')}}</label>
              <input
                class="form-control"
                name="nombre"
                ref="nombre"
                v-model="solicitud.nombre"
                :disabled="!esEditable()"
              />
            </div>
            <div class="col-md-3" v-show="tieneAlias('Apellido paterno')">
              <label>{{cargarAliasCampo('Apellido paterno')}}</label>
              <input
                class="form-control"
                name="apellido_paterno"
                v-model="solicitud.apellido_paterno"
                :disabled="!esEditable()"
              />
            </div>
            <div class="col-md-3" v-show="tieneAlias('Apellido materno')">
              <label>{{cargarAliasCampo('Apellido materno')}}</label>
              <input
                class="form-control"
                name="apellido_materno"
                v-model="solicitud.apellido_materno"
                :disabled="!esEditable()"
              />
            </div>
          </div>
        </div>
        <!-- / Fin de nuevo cliente -->

        <!-- Selección de cliente existente -->
        <div v-if="clienteNuevo == 0">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group" v-if="seleccionCliente == 0">
                <span
                  class="input-group-text"
                  v-if="solicitud.id_cliente != null"
                >
                  {{ solicitud.id_cliente }}
                </span>
                <input
                  type="text"
                  class="form-control"
                  :value="
                    `${solicitud.nombre} ${solicitud.apellido_paterno} ${
                      solicitud.apellido_materno
                    }`
                  "
                  readonly
                />
                <button
                  @click="seleccionCliente = 1"
                  v-if="
                    !(
                      solicitud.nuevo_id_estado == 3 ||
                      solicitud.nuevo_id_estado == 4
                    )
                  "
                  class="btn btn-success"
                >
                  <template v-if="solicitud.id_cliente == null">
                    <i class="mdi mdi-plus-thick"></i>
                    Seleccionar cliente
                  </template>

                  <template v-if="solicitud.id_cliente != null">
                    <i class="mdi mdi-account-group-outline"></i>
                    Cambiar cliente
                  </template>
                </button>
                
              </div>
            </div>
          </div>

          <cmp-seleccion-cliente
            v-if="seleccionCliente == 1"
            v-on:cliente-seleccionado="asignarClienteASolicitud($event)"
            v-on:cancelar="seleccionCliente = 0"
          ></cmp-seleccion-cliente>
          <br />
        </div>
        <!-- / Fin de selección de cliente existente -->

        <div
          v-if="
            (clienteNuevo == 1 || solicitud.id_cliente != null) &&
              seleccionCliente == 0
          "
        >
          <div class="row">
            <div class="col-md-3" v-show="tieneAlias('Tipo de cliente')">
              <label>{{cargarAliasCampo('Tipo de cliente')}}</label>
              <select
                class="form-select"
                name="empresa"
                v-model="solicitud.empresa"
                :disabled="!esEditable()"
              >
                <option value="0">Persona</option>
                <option value="1">Empresa</option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('RFC')">
              <label>{{cargarAliasCampo('RFC')}}</label>
              <input
                class="form-control"
                name="rfc"
                v-model="solicitud.rfc"
                :disabled="!esEditable()"
              />
            </div>
            <div class="col-md-3" v-show="tieneAlias('Sexo')">
              <label>{{cargarAliasCampo('Sexo')}}</label>
              <select
                class="form-select"
                name="sexo"
                v-model="solicitud.sexo"
                :disabled="!esEditable()"
              >
                <option value="hombre">Hombre</option>
                <option value="mujer">Mujer</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class=" col-md-6" v-show="tieneAlias('Teléfonos')">
              <label>{{cargarAliasCampo('Teléfonos')}}</label>

              <div class="row">
                <div class="col-12">
                  <table>
                    <tr>
                      <td>
                        <select
                          style="width: 80px"
                          class="form-select"
                          v-model="tipoTelefono"
                          :disabled="!esEditable()"
                        >
                          <option value="celular">Celular</option>
                          <option value="casa">Casa</option>
                          <option value="trabajo">Trabajo</option>
                          <option value="otro">Otro</option>
                        </select>
                      </td>
                      <td>
                        <input type="text" v-model="prefijo" title="Prefijo telefónico"
                          class="form-control" placeholder="+52"
                          :disabled="!esEditable()"
                        />
                      </td>
                      <td>
                        <input
                          ref="numeroTelefonico"
                          name="numeroTelefonico"
                          style="min-width: 82px"
                          type="text"
                          class="form-control"
                          v-model="numeroTelefono"
                          :disabled="!esEditable()"
                          v-on:keyup.enter="agregarTelefono()"
                          placeholder="55 1234 5678"
                        />
                      </td>
                      <td>
                        <button
                          class="btn btn-success"
                          @click="agregarTelefono()"
                          type="button"
                          :disabled="!esEditable()"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="table-responsive" style="height: 200px">
                <table id="tbl-telefonos" class="table table-hover">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Prefijo</th>
                      <th>Número</th>
                      <th style="width:50px" v-show="esEditable()">
                        <i class="fa fa-minus"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(telefono, index) in solicitud.telefonos"
                      :key="telefono.id"
                    >
                      <td>{{telefono.tipo}}</td>
                      <td>
                        <input
                          type="text" v-model="telefono.prefijo"
                          class="prefijo"
                          :class="modoTemplate == 'dark' ? 'text-gris' : 'text-dark'"
                        />
                      </td>
                      <td>
                        <input
                          type="text" v-model="telefono.numero" 
                          class="telefono"
                          :class="modoTemplate == 'dark' ? 'text-gris' : 'text-dark'"
                        />
                      </td>
                      <td v-show="esEditable()">
                        <button
                          class="btn btn-danger btn-sm"
                          @click="eliminarTelefono(index)"
                        >
                          <i class="mdi mdi-minus"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-3 col-md-5" v-show="tieneAlias('Correo electrónico')">
              <label>{{cargarAliasCampo('Correo electrónico')}}</label>
              <input
                class="form-control"
                name="correo"
                placeholder="correo@empresa.com"
                v-model="solicitud.correo"
                :disabled="!esEditable()"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" v-show="tieneAlias('Nota')">
              <label>{{cargarAliasCampo('Nota')}}</label>
              <textarea
                name="nota"
                rows="5"
                placeholder="Nota del cliente"
                class="form-control"
                v-model="solicitud.nota"
                :disabled="!esEditable()"
              ></textarea>
            </div>
          </div>

          <br /><br />
          <h4>Dirección del cliente</h4>
          <hr />

          <div class="row">
            <div class="col-md-6" v-show="tieneAlias('Calle')">
              <label>{{cargarAliasCampo('Calle')}}</label>
              <input
                class="form-control"
                name="calle"
                ref="calle"
                v-if="tieneOpciones('Calle')"
                v-model="solicitud.calle"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="calle"
                ref="calle"
                v-if="!tieneOpciones('Calle')"
                v-model="solicitud.calle"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Calle')"
                  :key="`opcion-calle-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('N° ext')">
              <label>{{cargarAliasCampo('N° ext')}}</label>
              <input
                class="form-control"
                name="numero_exterior"
                ref="numero_exterior"
                v-if="tieneOpciones('N° ext')"
                v-model="solicitud.numero_exterior"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="numero_exterior"
                ref="numero_exterior"
                v-if="!tieneOpciones('N° ext')"
                v-model="solicitud.numero_exterior"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('N° ext')"
                  :key="`opcion-numero-exterior-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('N° int')">
              <label>{{cargarAliasCampo('N° int')}}</label>
              <input
                class="form-control"
                name="numero_interior"
                v-if="tieneOpciones('N° int')"
                v-model="solicitud.numero_interior"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="numero_interior"
                v-if="!tieneOpciones('N° int')"
                v-model="solicitud.numero_interior"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('N° int')"
                  :key="`opcion-numero-interior-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3" v-show="tieneAlias('Colonia')">
              <label>{{cargarAliasCampo('Colonia')}}</label>
              <input
                class="form-control"
                name="colonia"
                v-if="tieneOpciones('Colonia')"
                v-model="solicitud.colonia"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="colonia"
                v-if="!tieneOpciones('Colonia')"
                v-model="solicitud.colonia"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Colonia')"
                  :key="`opcion-colonia-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('Delegación o Municipio')">
              <label>{{cargarAliasCampo('Delegación o Municipio')}}</label>
              <input
                class="form-control"
                name="municipio"
                v-if="tieneOpciones('Delegación o Municipio')"
                v-model="solicitud.municipio"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="municipio"
                v-if="!tieneOpciones('Delegación o Municipio')"
                v-model="solicitud.municipio"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Delegación o Municipio')"
                  :key="`opcion-minicipio-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('Código postal')">
              <label>{{cargarAliasCampo('Código postal')}}</label>
              <input
                class="form-control"
                name="codigo_postal"
                v-if="tieneOpciones('Código postal')"
                v-model="solicitud.codigo_postal"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="codigo_postal"
                v-if="!tieneOpciones('Código postal')"
                v-model="solicitud.codigo_postal"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Código postal')"
                  :key="`opcion-codigo-postal-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3" v-show="tieneAlias('Estado de residencia')">
              <label>{{cargarAliasCampo('Estado de residencia')}}</label>
              <input
                class="form-control"
                name="estado_residencia"
                v-if="tieneOpciones('Estado de residencia')"
                v-model="solicitud.estado_residencia"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="estado_residencia"
                v-if="!tieneOpciones('Estado de residencia')"
                v-model="solicitud.estado_residencia"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Estado de residencia')"
                  :key="`opcion-estado-residencia-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('País')">
              <label>{{cargarAliasCampo('País')}}</label>
              <input
                class="form-control"
                name="pais"
                v-if="tieneOpciones('País')"
                v-model="solicitud.pais"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                name="pais"
                v-if="!tieneOpciones('País')"
                v-model="solicitud.pais"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('País')"
                  :key="`opcion-pais-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin de datos generales del cliente -->

    <!-- Dirección de instalación -->
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Dirección de instalación
          <div class="form-check form-switch form-switch-lg ms-4 d-inline-block">
            <input
              v-model="mostrarMapa"
              class="form-check-input"
              type="checkbox"
              id="mostrarMapa"
            />
            <label class="form-check-label" for="mostrarMapa">Mapa</label>
          </div>
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nueva' ? guardar() : actualizar()"
                  v-if="!bandera_spinner && esEditable()"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner && esEditable()">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="modo == 'nueva'" class="row">
          <label class="col-md-4 pt-2">
            ¿La dirección de instalación es la misma que la del cliente?
          </label>
          <div class="col-md-2">
            <select v-model="direccionInstalacionIgualADireccionCliente" class="form-select">
              <option value="1">Si</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>

        <div v-if="direccionInstalacionIgualADireccionCliente == false">
          <div class="row">
            <div class="col-md-6" v-show="tieneAlias('Calle')">
              <label>{{cargarAliasCampo('Calle')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('Calle')"
                name="calle_instalacion"
                v-model="solicitud.calle_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('Calle')"
                name="calle_instalacion"
                v-model="solicitud.calle_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Calle')"
                  :key="`opcion-calle-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('N° ext')">
              <label>{{cargarAliasCampo('N° ext')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('N° ext')"
                name="numero_exterior_instalacion"
                v-model="solicitud.numero_exterior_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('N° ext')"
                name="numero_exterior_instalacion"
                v-model="solicitud.numero_exterior_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('N° ext')"
                  :key="`opcion-numero-exterior-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('N° int')">
              <label>{{cargarAliasCampo('N° int')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('N° int')"
                name="numero_interior_instalacion"
                v-model="solicitud.numero_interior_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('N° int')"
                name="numero_interior_instalacion"
                v-model="solicitud.numero_interior_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('N° int')"
                  :key="`opcion-numero-interior-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3" v-show="tieneAlias('Colonia')">
              <label>{{cargarAliasCampo('Colonia')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('Colonia')"
                name="colonia_instalacion"
                v-model="solicitud.colonia_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('Colonia')"
                name="colonia_instalacion"
                v-model="solicitud.colonia_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Colonia')"
                  :key="`opcion-colonia-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('Delegación o Municipio')">
              <label>{{cargarAliasCampo('Delegación o Municipio')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('Delegación o Municipio')"
                name="municipio_instalacion"
                v-model="solicitud.municipio_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('Delegación o Municipio')"
                name="municipio_instalacion"
                v-model="solicitud.municipio_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Delegación o Municipio')"
                  :key="`opcion-minicipio-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('Código postal')">
              <label>{{cargarAliasCampo('Código postal')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('Código postal')"
                name="codigo_postal_instalacion"
                v-model="solicitud.codigo_postal_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('Código postal')"
                name="codigo_postal_instalacion"
                v-model="solicitud.codigo_postal_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Código postal')"
                  :key="`opcion-codigo-postal-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3" v-show="tieneAlias('Estado de residencia')">
              <label>{{cargarAliasCampo('Estado de residencia')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('Estado de residencia')"
                name="estado_residencia_instalacion"
                v-model="solicitud.estado_residencia_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('Estado de residencia')"
                name="estado_residencia_instalacion"
                v-model="solicitud.estado_residencia_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('Estado de residencia')"
                  :key="`opcion-estado-residencia-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
            <div class="col-md-3" v-show="tieneAlias('País')">
              <label>{{cargarAliasCampo('País')}}</label>
              <input
                class="form-control"
                v-if="tieneOpciones('País')"
                name="pais_instalacion"
                v-model="solicitud.pais_instalacion"
                :disabled="!esEditable()"
              />
              <select
                class="form-select"
                v-if="!tieneOpciones('País')"
                name="pais_instalacion"
                v-model="solicitud.pais_instalacion"
                :disabled="!esEditable()"
              >
                <option
                  v-for="(opcion,index) in cargarOpciones('País')"
                  :key="`opcion-pais-${index+1}`"
                >
                  {{opcion.nombre}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="mostrarMapa">
          <br /><br />
          <h4>Posición de la instalación en el mapa</h4>
          <GoogleMap
            ref="mapa"
            :api-key="apikeyGoogle"
            :center="posicionInicial"
            style="height: 371px;"
            :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
            :zoom="mapaZoom"
          >
            <Marker
              ref="marker"
              :options="{ 
                position: posicionInicial,
                icon: markerIcon,
                draggable: esEditable(),
              }"
              @dragend="mostrarPosicion($event)"
            ></Marker>
          </GoogleMap>
          <br />
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-text">
                  <span class="d-none d-sm-block">
                    Latitud,Longitud
                  </span>
                  <span class="d-block d-sm-none">
                    Lat,Lng
                  </span>
                </span>
                <input
                  type="text"
                  class="form-control text-right"
                  v-model="lat_lng"
                  :readonly="bloquear_lat_lng"
                  :disabled="!esEditable()"
                  @change="actualizarPosicion()"
                />
                <button class="btn btn-light" 
                  @click="bloquear_lat_lng = !bloquear_lat_lng"
                >
                  <i
                    class="mdi"
                    :class="{
                      'mdi-lock-outline': bloquear_lat_lng,
                      'mdi-lock-open-variant-outline': !bloquear_lat_lng
                    }"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Fin de la dirección de instalación -->

    <!-- Referencias -->
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          <span class="d-none d-sm-block">
            Edición de solicitud de instalación de servicio
          </span>
          <span class="d-block d-sm-none">
            Referencias de instalación
          </span>
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nueva' ? guardar() : actualizar()"
                  v-if="!bandera_spinner && esEditable()"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner && esEditable()">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{modo == 'nueva' ? 'Guardar' : 'Actualizar'}}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <label>Referencias para llegar</label>
            <textarea
              class="form-control"
              placeholder="Referencias para llegar al lugar de la instalación"
              name="referencias"
              rows="5"
              v-model="solicitud.referencias"
              :disabled="!esEditable()"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Descripción de la casa</label>
            <textarea
              class="form-control"
              placeholder="Descripción de la casa de la instalación"
              name="descripcion_casa"
              rows="5"
              v-model="solicitud.descripcion_casa"
              :disabled="!esEditable()"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- /Fin de referencias -->

    <div class="text-right">
      <div class="btn-group">
        <a
        href="#"
        onclick="return false"
        class="btn btn-secondary"
        @click="cerrar()"
      >
        <i class="fa fa-angle-left"></i>
        Atras
      </a>
      <button
        class="btn btn-danger btn-outline"
        @click="preguntaCancelarSolicitud()"
        v-if="
          modo == 'edicion' &&
            !(solicitud.id_estado == 3 || solicitud.id_estado == 4)
        "
      >
        <span class="d-none d-sm-block">
          <i class="mdi mdi-cancel"></i>
          Cancelar solicitud
        </span>
        <span class="d-block d-sm-none">
          <i class="mdi mdi-cancel"></i>
        </span>
      </button>
      <button class="btn btn-soft-dark"
        v-if="modo == 'edicion' && solicitud.id_estado != 4"
        @click="imprimirSolicitud(solicitud)"
      >
        <span class="d-none d-sm-block">
          <i class="mdi mdi-printer"></i>
          Imprimir
        </span>
        <span class="d-block d-sm-none">
          <i class="mdi mdi-printer"></i>
        </span>
      </button>
      <button
        class="btn btn-success"
        @click="actualizar()"
        v-if="modo == 'edicion' && esEditable()"
        :disabled="bandera_spinner"
      >
        <span class="d-none d-sm-block">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </span>
        <span class="d-block d-sm-none">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
        </span>
      </button>
      <button
        class="btn btn-success"
        @click="guardar()"
        v-if="modo == 'nueva'"
        :disabled="bandera_spinner"
      >
        <i
          class="mdi"
          :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
        ></i>
        Guardar
      </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script src="./EdicionSolicitudInstalacion.js"></script>
<style scoped>
#cntTblServiciosDisponibles {
  height: 300px;
}
.prefijo {
  width: 100%;
  border: none;
  background-color: transparent;
}
.telefono {
  width: 100%;
  border: none;
  background-color: transparent;
}
.text-gris {
  color: #8C9FB9;
}
</style>